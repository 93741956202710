(function () {
  $(".anchor-link").on("click", function () {
    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top - 90
    }, 800);
  });

  //click outside - close modal
  $(".modal").on("click",function (e) {
    if (e.target != this) return;
    $('.modal').modal('hide');
  });

  //add href parameter in url
  $('.az-modal-anchor').on('click', function (e) {
    window.location.hash = $(this).attr('href');
  });

  //lightbox url
  var url = window.location.href;
  if (url.indexOf('Modal') > -1) {
    var hash = window.location.hash;
    var className = '.' + hash.substring(1);
    $('html, body').animate({ scrollTop: $(className).offset().top - 180 }, 'slow');
    $(hash).modal();
  }

  /**
   * [+] A-Z Boxes
   * TODO: Add a function to check if the current view are tablet and mobile
   * Refactoring is needed
   */

  $(".az-boxes > .box:gt(1)").addClass("collapsed");
  $(".az-boxes > .box").on("click", function (e) {
      //check the clicked element to stop event bubbling when click on child element (a tag) 
      var clickedElement = e.target.tagName.toLowerCase();
      //console.log(clickedElement);
      if(clickedElement !== 'a')
      {
        if (window.matchMedia("(max-width: 992px)").matches) {
          var index = $(".az-boxes > .box").index(this);
          if ($(this).hasClass("collapsed")) {
            $(".az-boxes > .box").addClass("collapsed");
            if (index % 2 === 0) {
              $(this).removeClass("collapsed");
              $(".az-boxes > .box")
                .eq(index + 1)
                .removeClass("collapsed");
            } else {
              $(this).removeClass("collapsed");
              $(".az-boxes > .box")
                .eq(index - 1)
                .removeClass("collapsed");
            }
          } else {
            $(".az-boxes > .box").addClass("collapsed");
          }
        }
      } 
  });
  /**
   * [-] A-Z Boxes
   */
})();
/**
 * [+] BINGO SCRIPTS
 */
const arr_permutation = [
  [
    "Feeling hopeless",
    "Difficulty sleeping",
    "Easily frustrated",
    "Social anxiety",
    "Unexplained body aches",
    "Loss of interest in daily activities",
    "An overactive imagination",
    "Persistent negative thoughts",
    "Losing control easily",
    "Inability to relax",
    "Changes in appetite",
    "Mind feels foggy",
    "Feeling overwhelmed",
    "Uncontrollable worry",
    "Unexplained weight changes",
    "Unexplained aggression",
  ],
  [
    "Paranoia",
    "Low self-esteem",
    "Excessive agitation",
    "Difficulty staying awake",
    "Frequent moodiness",
    "Impulsive behaviour",
    "Feeling lost or adrift",
    "Losing control easily",
    "Extreme mood changes",
    "Rapid heartbeat",
    "Withdrawing from social activities",
    "Substance dependance ",
    "Loss of energy",
    "Hallucinations",
    "Concentration issues",
    "Insomnia",
  ],
  [
    "Inability to relax",
    "Changes in appetite",
    "Self-loathing",
    "Uncontrollable worry",
    "Easily angered",
    "Muscle tension",
    "Mind feels foggy",
    "Concentration issues",
    "Persistent negative thoughts",
    "Difficulty sleeping",
    "Feeling helpless",
    "Tearfulness",
    "Frequent moodiness",
    "Unexplained aggression",
    "Social anxiety",
    "Reckless behaviour",
  ],
];

var random_index = Math.floor(Math.random() * 3);
var random_permutation = arr_permutation[random_index].sort(function (a, b) {
  return Math.random() - 0.5;
});

function generateBingoCard() {
  var arr_str = [];

  //console.log('index:' + random_index);
  //console.log('Permutations:' + random_permutation);

  random_permutation.map(function (item, index) {
    //console.log(index, item);
    arr_str.push(
      '<div class="box_element" data-value="' +
      item +
      '" data-index="' +
      index +
      '"><span>' +
      item +
      "</span></div>"
    );
  });

  $("#bingo_box_container").html(arr_str.join(""));
}

/**
 * [+] BINGO SCRIPTS
 */


/**
* [+] TypeForm SCRIPTS
*/
(function () {
  var qs, js, q, s, d = document,
    gi = d.getElementById,
    ce = d.createElement,
    gt = d.getElementsByTagName,
    id = "typef_orm_share",
    b = "https://embed.typeform.com/";
  if (!gi.call(d, id)) {
    js = ce.call(d, "script");
    js.id = id;
    js.src = b + "embed.js";
    q = gt.call(d, "script")[0];
    q.parentNode.insertBefore(js, q)
  }
})()
 /**
* [-] TypeForm SCRIPTS
*/